.Projects {
   padding: 1em;
   background-color: var(--secondary-color);
   position: relative;
}
.Projects h2 {
   text-align: center;
   margin: 1em 0;
   color: #fff;
}
.Projects img {
   border-radius: 5px;
   -o-object-fit: cover;
   object-fit: cover;
   box-shadow: 0 4px 30px 5px rgba(33, 31, 32, 0.1882352941);
   cursor: pointer;
}
.Projects .viewImg {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 90%; /* Make the overlay fill 90% of the screen width */
   z-index: 100; /* Ensure the image is on top of other content */
   text-align: center;
}
.Projects .viewImg img {
   cursor: default;
   box-shadow: 0 4px 30px 5px rgba(33, 31, 32, 0.5647058824);
   border-radius: 0px;
   width: 30%; /* Make the image fill the entire width of the overlay */
   height: auto;
}
.Projects .viewImg button {
   width: 30%;
   margin: 2em;
   padding: 1em 0;
   border-radius: 20px;
   border: none;
   border: solid 2px var(--primary-color);
   color: var(--primary-color);
   background: none;
   cursor: pointer;
}
@media only screen and (max-width: 900px) {
   .Projects {
      .viewImg {
         img {
            width: 70%;
         }
      }
   }
}
@media only screen and (max-width: 500px) {
   .Projects {
      .viewImg {
         img {
            width: 90%;
         }
      }
   }
}
